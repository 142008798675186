<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:" @click="goBack" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div v-if="fileItems.length > 0" class="header-column header-util right">
        <div class="util util-download">
          <a href="javascript:" @click="isFileAtchListShow = true" class="util-actions util-actions-download">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container helpdesk">
        <div class="view-header">
          <div class="badge-wrap">
            <span class="status-badge gray">{{items.ansYn === 'Y' ? '답변완료' : '처리중'}}</span>
            <i v-if="items.newQna === 'Y'" class="icon-new"></i>
          </div>
          <div class="title-wrap">
            <h3 class="title">{{items.inqTitle}}</h3>
            <p class="subtitle">
              <span class="text">{{ items.deptNm }}  {{ items.jbgdNm}} {{items.jbpsNm}} {{items.lrnerNm}}</span>
              <span class="text">{{getDateFormat('yyyy.MM.dd hh:mm', items.regDt)}} 발송</span>
            </p>
          </div>
        </div>
        <div class="view-body">
          <div class="view-content" v-html="items.inqCn"></div>
          <div v-if="items.ansYn === 'Y'" class="view-content" v-html="items.ansCn"></div>
          <div v-if="items.ansYn === 'Y'" class="view-meta">
            <span class="text">관리자</span>
            <span class="text">{{getDateFormat('yyyy.MM.dd hh:mm', items.mdfcnDt)}}</span>
          </div>
        </div>
        <div v-if="items.lrnerId === sessionId" class="view-bottom">
          <button v-if="items.ansYn === 'N'" @click="updateInq(items.comInqSn)" class="kb-btn kb-btn-light-silver-border kb-btn-w100">
            <span class="text text-black">수정</span>
          </button>
          <button @click="deleteInq(items.comInqSn)" class="kb-btn kb-btn-light-silver-border kb-btn-w100">
            <span class="text text-black">삭제</span>
          </button>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <HelpMobileQnaFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="fileItems"/>
  <!-- end::kb-main -->
</template>

<script>
import {onMounted, ref, computed} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ACT_DELETE_HELP_QNA, ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_QNA} from "@/store/modules/help/help";
import {getItem, getItems, isSuccess, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import HelpMobileQnaFileAtch from "@/views/pages/help/mobile/HelpMobileQnaFileAtch";

export default {
  name: 'HelpQnaView',
  components:{
    HelpMobileQnaFileAtch
  },
  setup(){
    const inqTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const showAttachments = ref(false);
    const sessionId = ref(store.state.auth.session.lrnerId);
    const {showMessage} = useAlert();
    const isFileAtchListShow = ref(false);

    onMounted(() => {
      getHelpQa();
    })

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getHelpQa = () => {
      store.dispatch(`help/${ACT_GET_HELP_QNA}`, route.params.qnaSn
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(items.value.regDt, 'yyyy-MM-dd'));
          let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
          let date = new Date(diff).getDate();
          if(month + date <= 7) items.value.newQna = 'Y';
          else items.value.newQna = 'N';
          getHelpQaFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getHelpQaFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comInqSn: route.params.qnaSn
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    }

    const updateInq = (inqSn) => {
      router.push({path: '/help/qna/write', query:{'comInqSn': inqSn}});
    }

    const deleteInq = (comInqSn) => {
      store.dispatch(`help/${ACT_DELETE_HELP_QNA}`,
        comInqSn
      ).then(res => {
        if (isSuccess(res)) {
          showMessage("QA를 삭제했습니다.", router.go(-1));
        } else {
          showMessage("QA삭제 실패. <br> 관리자에게 문의해주세요.");
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const fileDown = (item) => {
      if(sessionId.value === item.value.rgtrId){
        if (item.buketNm === 'pea.hrd.aws.prd.s3.contents') {
          window.location.href = `https://lxpcms.kbstar.com/${item.upldLoc}`;
        } else if (item.buketNm === 'pea.hrd.aws.dev.s3.contents') {
          window.location.href = `https://devlxpcms.kbstar.com/${item.upldLoc}`;
        }
      }
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      sessionId,
      inqTitle,
      items,
      fileItems,
      showAttachments,
      isFileAtchListShow,
      getDateFormat,
      updateInq,
      deleteInq,
      fileDown,
      goBack,
    }
  }
};
</script>